import { FormEvent, useCallback, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { METRIKA_COUNTER, METRIKA_GOALS, ROUTES } from '@shared/config';
import { ModalContext } from '@shared/ui/layout/context';
import '@shared/ui/sixthFrame/index.css';

export const SixthFrame = () => {
  const { isSuccessSend, formInputs, formCheckboxes, onSubmit, onOpenModal } =
    useContext(ModalContext);

  useEffect(() => {
    if (isSuccessSend !== null) {
      onOpenModal();
    }
  }, [isSuccessSend, onOpenModal]);

  const onSubmitForm = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ym(METRIKA_COUNTER, 'reachGoal', METRIKA_GOALS.submitFooterForm);
      onSubmit(event);
    },
    [onSubmit],
  );

  return (
    <section className='sixth-frame' id='contacts'>
      <div className='content'>
        <div className='description'>
          <h3 className='title-48 mb-24'>Оставьте ваши контакты</h3>
          <p className='text-20'>
            Мы позвоним, чтобы больше узнать о вашем бизнесе и договориться об удобном
            времени для презентации
          </p>
        </div>
        <form onSubmit={onSubmitForm}>
          {formInputs}
          <div className='mb-24'>
            {formCheckboxes}
            <Link to={ROUTES.consent}>Согласие на обработку персональных данных</Link>
          </div>
          <button className='text-16' type='submit'>
            Заказать демо
          </button>
        </form>
      </div>
    </section>
  );
};
