import type { Modal } from 'bootstrap';
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { METRIKA_COUNTER, METRIKA_GOALS, SUBJECTS } from '@shared/config';
import { useForm } from '@shared/hooks/useForm';
import { Footer } from '@shared/ui/footer';
import { ModalContext } from '@shared/ui/layout/context';
import { ModalForm } from '@shared/ui/modalForm';
import { FileNames, TextTypes } from '@shared/ui/modalForm/enum';
import { Navigation } from '@shared/ui/navigation';

type Props = {
  children: ReactNode;
  subject?: string;
};

export const Layout: FunctionComponent<Props> = ({
  children,
  subject = SUBJECTS.newRequest,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [modal, setModal] = useState<null | Modal>(null);
  const [modalType, setModalType] = useState<TextTypes>(TextTypes.requestDemo);
  const [fileName, setFileName] = useState<null | FileNames>(null);
  const {
    setFormState,
    isSuccessSend,
    setIsSuccessSend,
    formInputs,
    formCheckboxes,
    onSubmit,
  } = useForm({ subject, fileName });

  useEffect(() => {
    require('sourcebuster').init();

    if (modalRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setModal(new bootstrap.Modal(modalRef.current));
    }
  }, []);

  const onOpenModal = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ym(METRIKA_COUNTER, 'reachGoal', METRIKA_GOALS.openPopupForm);

    if (modal) {
      modal.show();
    }
  }, [modal]);

  const onCloseClick = useCallback(() => {
    if (modal) {
      modal.hide();
    }
  }, [modal]);

  const contextValue = useMemo(() => {
    return {
      isSuccessSend,
      modalType,
      formInputs,
      formCheckboxes,
      setIsSuccessSend,
      setFormState,
      setModalType,
      setFileName,
      onOpenModal,
      onSubmit,
    };
  }, [
    formCheckboxes,
    formInputs,
    isSuccessSend,
    modalType,
    onOpenModal,
    onSubmit,
    setFormState,
    setIsSuccessSend,
  ]);

  return (
    <ModalContext.Provider value={contextValue}>
      <Navigation />
      <main className='main'>{children}</main>
      <Footer />
      <ModalForm ref={modalRef} onCloseClick={onCloseClick} />
    </ModalContext.Provider>
  );
};
