import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ROUTES } from '@shared/config';
import { ModalContext } from '@shared/ui/layout/context';
import MessageIcon from '@shared/ui/researchJuneFrame/images/message-icon.svg';
import '@shared/ui/researchJuneFrame/index.css';

export const ResearchJuneFrame = () => {
  const { isSuccessSend, formInputs, formCheckboxes, onSubmit, onOpenModal } =
    useContext(ModalContext);

  useEffect(() => {
    if (isSuccessSend !== null) {
      onOpenModal();
    }
  }, [isSuccessSend, onOpenModal]);

  return (
    <section className='research-june-frame'>
      <div className='content'>
        <div className='description'>
          <img className='mb-37' src={MessageIcon} alt='Контакты' />
          <h3 className='title-48 mb-24'>Оставьте контакты</h3>
          <p className='text-20'>
            Мы отправим вам результаты исследования «Пути цифровизации девелоперов и
            генеральных застройщиков в России в 2024 году». Результаты исследования будут
            готовы в июне.
          </p>
        </div>
        <form onSubmit={onSubmit}>
          {formInputs}
          <div className='mb-24'>
            {formCheckboxes}
            <Link to={ROUTES.consent}>Согласие на обработку персональных данных</Link>
          </div>
          <button className='text-16' type='submit'>
            Получить результаты исследования
          </button>
        </form>
      </div>
    </section>
  );
};
