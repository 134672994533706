import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import Widgets from '@shared/ui/firstFrame/images/widgets.webp';
import { ModalContext } from '@shared/ui/layout/context';
import '@shared/ui/firstFrame/index.css';

const arrayTitles = ['оптимизации', 'масштабирования', 'контроля'];

export const FirstFrame = () => {
  const { onOpenModal } = useContext(ModalContext);

  const headingRef = useRef<HTMLHeadingElement>(null);
  const [indexTitle, setIndexTitle] = useState(0);

  const changeTitle = useCallback(() => {
    if (indexTitle < arrayTitles.length - 1) {
      setIndexTitle(prevIndex => prevIndex + 1);
    } else {
      setIndexTitle(0);
    }
  }, [indexTitle]);

  useEffect(() => {
    const titleDomNode = headingRef.current;

    titleDomNode?.addEventListener('animationiteration', changeTitle);

    return () => {
      titleDomNode?.removeEventListener('animationiteration', changeTitle);
    };
  }, [changeTitle, headingRef]);

  return (
    <section className='first-frame mb-128'>
      <div className='title'>
        <h4 className='title-48'>Платформа для</h4>
        <div>
          <h4 ref={headingRef} className='title-48 animated-title-48'>
            {arrayTitles[indexTitle]}
          </h4>
        </div>
        <h4 className='title-48 mb-32'>строительного бизнеса</h4>
        <p className='text-24'>
          Поможем уменьшить количество ручного труда в компании, снизить число ошибок и
          подготовить ваш бизнес к быстрому масштабированию
        </p>
      </div>
      <div className='img-wrap'>
        <img loading='lazy' src={Widgets} alt='widgets' />
      </div>
      <button onClick={onOpenModal}>Заказать демо</button>
    </section>
  );
};
