import { useLoaderData } from 'react-router-dom';

import Pdf from '@pages/products/images/pdf.svg';
import { openapi } from '@shared/api';
import { Layout } from '@shared/ui/layout';
import '@pages/product/ui/index.css';

export const Product = () => {
  const data = useLoaderData() as Awaited<
    ReturnType<typeof openapi.v1DocsFilesProductsRetrieve>
  >;

  if (!data) return null;

  return (
    <Layout>
      <section className='product-frame'>
        <div className='product'>
          <div className='product-name'>
            {data.logoUrl && (
              <img src={data.logoUrl} width={40} loading='lazy' alt='лого продукта' />
            )}
            <h3>{data.name}</h3>
          </div>
          <p>{data.description}</p>
          {data.registryInfo && (
            <p>
              Продукт зарегистрирован в{' '}
              <a href='https://reestr.digital.gov.ru/' target='_blank' rel='noreferrer'>
                Едином реестре российских программ
              </a>{' '}
              для ЭВМ и баз данных. Реестровая запись:{' '}
              <a
                className='product-record'
                href={data.registryInfo.registryUrl}
                target='_blank'
                rel='noreferrer'
              >
                {data.registryInfo.registryNumber}
              </a>
            </p>
          )}
        </div>
        <div className='manual'>
          {data.files.map((file, index) => (
            <div className='manual-item' key={index}>
              <img className='manual-img' src={Pdf} loading='lazy' alt='скачать пдф' />
              <div>
                <h6>{file.name}</h6>
                <a
                  className='download'
                  href={file.url}
                  download
                  target='_blank'
                  rel='noreferrer'
                >
                  Скачать документ
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  );
};
