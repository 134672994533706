import { FormEvent, forwardRef, useCallback, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { METRIKA_COUNTER, METRIKA_GOALS, ROUTES } from '@shared/config';
import { formInitialState } from '@shared/hooks/useForm';
import { ModalContext } from '@shared/ui/layout/context';
import { TextTypes } from '@shared/ui/modalForm/enum';
import CloseIcon from '@shared/ui/modalForm/images/close.svg';
import '@shared/ui/modalForm/index.css';

const commonText = {
  description: 'Оставьте свои контакты, чтобы узнать больше о продукте',
  buttonText: 'Скачать презентацию',
};

const getTextByType = (
  type: TextTypes,
): { title: string; description: string; buttonText: string } => {
  return {
    [TextTypes.requestDemo]: {
      title: 'Заказать демо',
      description:
        'Мы позвоним, чтобы больше узнать о вашем бизнесе и договориться об удобном времени для презентации',
      buttonText: 'Заказать демо',
    },
    [TextTypes.managerProcurement]: {
      title: 'Управление закупками',
      ...commonText,
    },
    [TextTypes.documentation]: {
      title: 'Документация',
      ...commonText,
    },
    [TextTypes.controlQuality]: {
      title: 'Контроль качества стройки',
      ...commonText,
    },
    [TextTypes.monitoring]: {
      title: 'ИИ-мониторинг',
      ...commonText,
    },
    [TextTypes.security]: {
      title: 'Безопасность',
      ...commonText,
    },
  }[type];
};

type Props = {
  onCloseClick: () => void;
};

export const ModalForm = forwardRef<HTMLDivElement, Props>(({ onCloseClick }, ref) => {
  const {
    modalType,
    isSuccessSend,
    formInputs,
    formCheckboxes,
    setModalType,
    setFileName,
    setFormState,
    setIsSuccessSend,
    onSubmit,
  } = useContext(ModalContext);

  const { title, description, buttonText } = useMemo(() => {
    return getTextByType(modalType);
  }, [modalType]);

  const onClose = useCallback(() => {
    onCloseClick();
    setFormState(formInitialState);
    setIsSuccessSend(null);
    setModalType(TextTypes.requestDemo);
    setFileName(null);
  }, [onCloseClick, setFileName, setFormState, setIsSuccessSend, setModalType]);

  const onSubmitForm = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ym(METRIKA_COUNTER, 'reachGoal', METRIKA_GOALS.submitPopupForm);
      onSubmit(event);
    },
    [onSubmit],
  );

  const FormContent = useMemo(() => {
    return (
      <>
        <h2 className='title-40 mb-24'>{title}</h2>
        <p className='text-20 mb-24'>{description}</p>
        <form onSubmit={onSubmitForm}>
          {formInputs}
          <div className='mb-24'>
            {formCheckboxes}
            <Link to={ROUTES.consent} onClick={onClose}>
              Согласие на обработку персональных данных
            </Link>
          </div>
          <button className='text-16' type='submit'>
            {buttonText}
          </button>
        </form>
      </>
    );
  }, [buttonText, description, formCheckboxes, formInputs, onClose, onSubmitForm, title]);
  const AfterSubmitContent = useMemo(() => {
    return (
      <>
        <h2 className='title-40 mb-24 text-center'>
          {isSuccessSend ? 'Спасибо!' : 'Ошибка!'}
        </h2>
        <p className='text-20 text-center'>
          {isSuccessSend ? 'Скоро мы свяжемся с вами' : 'Попробуйте ещё раз'}
        </p>
      </>
    );
  }, [isSuccessSend]);

  return (
    <div
      ref={ref}
      className='modal-form modal fade'
      tabIndex={-1}
      aria-labelledby='modal'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='close-wrapper'>
            <img loading='lazy' src={CloseIcon} alt='close icon' onClick={onClose} />
          </div>
          <div className='modal-body'>
            {isSuccessSend === null ? FormContent : AfterSubmitContent}
          </div>
        </div>
      </div>
    </div>
  );
});

ModalForm.displayName = 'ModalForm';
