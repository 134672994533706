/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RegistryInfo } from './RegistryInfo';
import {
  RegistryInfoFromJSON,
  RegistryInfoFromJSONTyped,
  RegistryInfoToJSON,
} from './RegistryInfo';
import type { DocsFiles } from './DocsFiles';
import { DocsFilesFromJSON, DocsFilesFromJSONTyped, DocsFilesToJSON } from './DocsFiles';

/**
 *
 * @export
 * @interface ProductDetail
 */
export interface ProductDetail {
  /**
   *
   * @type {number}
   * @memberof ProductDetail
   */
  readonly id: number;
  /**
   * Название
   * @type {string}
   * @memberof ProductDetail
   */
  name: string;
  /**
   * Описание
   * @type {string}
   * @memberof ProductDetail
   */
  description: string;
  /**
   * Запись в реестре
   * @type {RegistryInfo}
   * @memberof ProductDetail
   */
  readonly registryInfo: RegistryInfo | null;
  /**
   * URL логотипа продукта
   * @type {string}
   * @memberof ProductDetail
   */
  readonly logoUrl: string | null;
  /**
   * Документы продукта
   * @type {Array<DocsFiles>}
   * @memberof ProductDetail
   */
  readonly files: Array<DocsFiles>;
}

/**
 * Check if a given object implements the ProductDetail interface.
 */
export function instanceOfProductDetail(value: object): value is ProductDetail {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('description' in value) || value['description'] === undefined) return false;
  if (!('registryInfo' in value) || value['registryInfo'] === undefined) return false;
  if (!('logoUrl' in value) || value['logoUrl'] === undefined) return false;
  if (!('files' in value) || value['files'] === undefined) return false;
  return true;
}

export function ProductDetailFromJSON(json: any): ProductDetail {
  return ProductDetailFromJSONTyped(json, false);
}

export function ProductDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProductDetail {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    description: json['description'],
    registryInfo: RegistryInfoFromJSON(json['registry_info']),
    logoUrl: json['logo_url'],
    files: (json['files'] as Array<any>).map(DocsFilesFromJSON),
  };
}

export function ProductDetailToJSON(
  value?: Omit<ProductDetail, 'id' | 'registry_info' | 'logo_url' | 'files'> | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    name: value['name'],
    description: value['description'],
  };
}
