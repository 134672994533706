/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Product
 */
export interface Product {
  /**
   *
   * @type {number}
   * @memberof Product
   */
  readonly id: number;
  /**
   * Название
   * @type {string}
   * @memberof Product
   */
  name: string;
  /**
   * Описание
   * @type {string}
   * @memberof Product
   */
  description: string;
  /**
   * Номер записи в реестре
   * @type {string}
   * @memberof Product
   */
  readonly registryNumber: string | null;
  /**
   * URL логотипа продукта
   * @type {string}
   * @memberof Product
   */
  readonly logoUrl: string | null;
}

/**
 * Check if a given object implements the Product interface.
 */
export function instanceOfProduct(value: object): value is Product {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('description' in value) || value['description'] === undefined) return false;
  if (!('registryNumber' in value) || value['registryNumber'] === undefined) return false;
  if (!('logoUrl' in value) || value['logoUrl'] === undefined) return false;
  return true;
}

export function ProductFromJSON(json: any): Product {
  return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    description: json['description'],
    registryNumber: json['registry_number'],
    logoUrl: json['logo_url'],
  };
}

export function ProductToJSON(
  value?: Omit<Product, 'id' | 'registry_number' | 'logo_url'> | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    name: value['name'],
    description: value['description'],
  };
}
