import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useLoaderData } from 'react-router-dom';

import Info from '@pages/products/images/info.svg';
import { openapi } from '@shared/api';
import { Layout } from '@shared/ui/layout';
import '@pages/products/ui/index.css';

export const Products = () => {
  const data = useLoaderData() as Awaited<
    ReturnType<typeof openapi.v1DocsFilesProductsList>
  >;

  return (
    <Layout>
      <section className='products-frame'>
        <div className='content'>
          <h3>Программные продукты</h3>
          <div className='products-wrapper'>
            {data.map((product, index) => (
              <Link className='product' key={index} to={String(product.id)}>
                {product.logoUrl && (
                  <img
                    src={product.logoUrl}
                    width={40}
                    loading='lazy'
                    alt='лого продукта'
                  />
                )}
                <span className='product-name'>{product.name}</span>
                {product.registryNumber && (
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip>
                        Продукт зарегистрирован в Едином реестре российских программ для
                        ЭВМ и баз данных. Реестровая запись: {product.registryNumber}
                      </Tooltip>
                    }
                  >
                    <img className='info' src={Info} alt='info' loading='lazy' />
                  </OverlayTrigger>
                )}
              </Link>
            ))}
          </div>
          <p className='price-description'>
            Стоимость использования программных продуктов рассчитывается индивидуально.
            Для расчета стоимости вы можете связаться с нами по электронной почте{' '}
            <a
              className='price-mail'
              href='mailto:smlttech@samolet.ru'
              target='_blank'
              rel='noreferrer'
            >
              smlttech@samolet.ru
            </a>
          </p>
        </div>
      </section>
    </Layout>
  );
};
