import { useContext } from 'react';

import IndicatorsIcon from '@shared/ui/fifthFrame/images/indicators.webp';
import { ModalContext } from '@shared/ui/layout/context';
import '@shared/ui/fifthFrame/index.css';

export const FifthFrame = () => {
  const { onOpenModal } = useContext(ModalContext);

  return (
    <section className='fifth-frame'>
      <div className='header-block mb-128'>
        <div>
          <h2>Автоматизируйте</h2>
          <h2>стройку</h2>
        </div>
        <button onClick={onOpenModal}>Заказать демо</button>
      </div>
      <h3 className='title-40 mb-32'>Как мы работаем</h3>
      <div className='mobile-scroll'>
        <div className='cards-wrapper mb-64'>
          <div className='custom-card'>
            <div className='circle mb-32'>1</div>
            <h4 className='mb-12'>Знакомимся</h4>
            <p>
              Позвоним, чтобы больше узнать о вашем бизнесе и договориться о времени для
              презентации
            </p>
          </div>
          <div className='custom-card'>
            <div className='circle mb-32'>2</div>
            <h4 className='mb-12'>Презентуем платформу</h4>
            <p>Покажем, как работают все продукты, и&nbsp;поможем выбрать решение</p>
          </div>
          <div className='custom-card'>
            <div className='circle mb-32'>3</div>
            <h4 className='mb-12'>Поможем внедрить</h4>
            <p>
              Вместе разработаем пошаговый план внедрения платформы и обучения сотрудников
            </p>
          </div>
        </div>
      </div>
      <h3 className='title-40 mb-32'>О нас</h3>
      <div className='content-card'>
        <div className='description'>
          <p className='text-20 mb-48'>
            Платформа «Самолет 10D» предназначена для управления и автоматизации всего
            строительного цикла: от проектирования до сдачи квартир.
            <br />
            <br />
            Ключевое качество экосистемы — её эффективность, доказанная на практике.
          </p>
          <div className='indicators'>
            <div className='indicator'>
              <p className='text-16'>Выручка</p>
              <p className='text-24 medium'>172,2 млрд</p>
              <div className='ellipse text-16'>х4,4</div>
            </div>
            <div className='indicator'>
              <p className='text-16'>EBITDA</p>
              <p className='text-24 medium'>48,2 млрд</p>
              <div className='ellipse text-16'>х9,3</div>
            </div>
            <div className='indicator'>
              <p className='text-16'>Чистая прибыль</p>
              <p className='text-24 medium'>15,3 млрд</p>
              <div className='ellipse text-16'>х4,9</div>
            </div>
          </div>
        </div>
        <img
          loading='lazy'
          className='indicators-img'
          src={IndicatorsIcon}
          alt='indicators icon'
        />
        <div className='indicators mobile'>
          <div className='indicator'>
            <p className='text-16'>Выручка</p>
            <p className='text-24 medium'>172,2 млрд</p>
            <div className='ellipse text-16'>х4,4</div>
          </div>
          <div className='indicator'>
            <p className='text-16'>EBITDA</p>
            <p className='text-24 medium'>48,2 млрд</p>
            <div className='ellipse text-16'>х9,3</div>
          </div>
          <div className='indicator'>
            <p className='text-16'>Чистая прибыль</p>
            <p className='text-24 medium'>15,3 млрд</p>
            <div className='ellipse text-16'>х4,9</div>
          </div>
        </div>
      </div>
    </section>
  );
};
