import { useCallback, useContext, useEffect, useRef } from 'react';

import PrevIcon from '/images/prev.svg';

import AccreditationIcon from '@shared/ui/fourthFrame/images/accreditation-icon.svg';
import BlockedDocumentIcon from '@shared/ui/fourthFrame/images/blocked-document-icon.svg';
import ChartsIcon from '@shared/ui/fourthFrame/images/charts-icon.svg';
import ControlQualityFirstIcon from '@shared/ui/fourthFrame/images/control-quality-1.webp';
import ControlQualitySecondIcon from '@shared/ui/fourthFrame/images/control-quality-2.webp';
import ControlQualityThirdIcon from '@shared/ui/fourthFrame/images/control-quality-3.svg';
import DocumentIcon from '@shared/ui/fourthFrame/images/document-icon.svg';
import DocumentationFirstIcon from '@shared/ui/fourthFrame/images/documentation-1.webp';
import DocumentationSecondIcon from '@shared/ui/fourthFrame/images/documentation-2.webp';
import DocumentationThirdIcon from '@shared/ui/fourthFrame/images/documentation-3.webp';
import EventIcon from '@shared/ui/fourthFrame/images/event-icon.svg';
import MobileIcon from '@shared/ui/fourthFrame/images/mobile-icon.svg';
import MonitorIcon from '@shared/ui/fourthFrame/images/monitor-icon.svg';
import MonitoringFirstIcon from '@shared/ui/fourthFrame/images/monitoring-1.webp';
import MonitoringSecondIcon from '@shared/ui/fourthFrame/images/monitoring-2.webp';
import PassIcon from '@shared/ui/fourthFrame/images/pass-icon.svg';
import ProcurementManagementFirstIcon from '@shared/ui/fourthFrame/images/procurement-management-1.webp';
import ProcurementManagementSecondIcon from '@shared/ui/fourthFrame/images/procurement-management-2.webp';
import ProcurementManagementThirdIcon from '@shared/ui/fourthFrame/images/procurement-management-3.svg';
import SecurityFirstIcon from '@shared/ui/fourthFrame/images/security-1.svg';
import SecuritySecondIcon from '@shared/ui/fourthFrame/images/security-2.webp';
import SecurityThirdIcon from '@shared/ui/fourthFrame/images/security-3.webp';
import SettingsIcon from '@shared/ui/fourthFrame/images/settings-icon.svg';
import StepIcon from '@shared/ui/fourthFrame/images/steps-icon.svg';
import TopIcon from '@shared/ui/fourthFrame/images/top-icon.svg';
import VisibleIcon from '@shared/ui/fourthFrame/images/visible-icon.svg';
import { ModalContext } from '@shared/ui/layout/context';
import { FileNames, TextTypes } from '@shared/ui/modalForm/enum';
import '@shared/ui/fourthFrame/index.css';

const carouselInterval = 5000;

export const FourthFrame = () => {
  const { setModalType, setFileName, onOpenModal } = useContext(ModalContext);

  const carouselManagerProcurementRef = useRef<HTMLDivElement>(null);
  const carouselDocumentationRef = useRef<HTMLDivElement>(null);
  const carouselControlQualityRef = useRef<HTMLDivElement>(null);
  const carouselMonitoringRef = useRef<HTMLDivElement>(null);
  const carouselSecurityRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    [
      carouselManagerProcurementRef,
      carouselDocumentationRef,
      carouselControlQualityRef,
      carouselMonitoringRef,
      carouselSecurityRef,
    ].forEach(ref => {
      if (ref.current) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        new bootstrap.Carousel(ref.current, {
          interval: carouselInterval,
          ride: 'carousel',
        });
      }
    });
  }, []);

  const onButtonClick = useCallback(
    (type: TextTypes, fileName: FileNames) => {
      return () => {
        setModalType(TextTypes[type]);
        setFileName(fileName);
        onOpenModal();
      };
    },
    [onOpenModal, setFileName, setModalType],
  );

  return (
    <section className='fourth-frame default-padding mb-128'>
      <div className='slider-card mb-64' id='solutions'>
        <div className='content pr-16'>
          <h4 className='title-40 mb-24'>Управление закупками</h4>
          <p className='text-20 mb-48'>
            Самостоятельно проводите торги и контролируйте поставки материалов. Управляйте
            всем процессом: от формирования требований к тендеру до финальной оплаты
            контракта.
          </p>
          <div className='short-description mb-16'>
            <img loading='lazy' src={AccreditationIcon} alt='accreditation icon' />
            <p className='text-16'>Быстрая аккредитация партнёров</p>
          </div>
          <div className='short-description mb-16'>
            <img loading='lazy' src={SettingsIcon} alt='settings icon' />
            <p className='text-16'>Гибкие настройки торгов и система статусов</p>
          </div>
          <div className='short-description mb-48'>
            <img loading='lazy' src={MobileIcon} alt='mobile icon' />
            <p className='text-16'>Мобильное приложение для приёмки материалов</p>
          </div>
          <button
            className='text-16 primary-button download'
            onClick={onButtonClick(
              TextTypes.managerProcurement,
              FileNames.managerProcurement,
            )}
          >
            Скачать презентацию
          </button>
        </div>
        <div
          ref={carouselManagerProcurementRef}
          id='carouselManagerProcurement'
          className='carousel slide'
          data-bs-ride='carousel'
        >
          <div className='carousel-indicators'>
            <button
              className='active'
              type='button'
              data-bs-target='#carouselManagerProcurement'
              data-bs-slide-to='0'
              aria-current='true'
              aria-label='Slide 1'
            />
            <button
              type='button'
              data-bs-target='#carouselManagerProcurement'
              data-bs-slide-to='1'
              aria-label='Slide 2'
            />
            <button
              type='button'
              data-bs-target='#carouselManagerProcurement'
              data-bs-slide-to='2'
              aria-label='Slide 3'
            />
          </div>
          <div className='carousel-inner'>
            <div className='carousel-item active' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                className='d-block'
                src={ProcurementManagementFirstIcon}
                alt='slider image'
              />
            </div>
            <div className='carousel-item' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                className='d-block'
                src={ProcurementManagementSecondIcon}
                alt='slider image'
              />
            </div>
            <div className='carousel-item' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                className='d-block'
                src={ProcurementManagementThirdIcon}
                alt='slider image'
              />
            </div>
          </div>
          <button
            className='carousel-control-prev'
            type='button'
            data-bs-target='#carouselManagerProcurement'
            data-bs-slide='prev'
          >
            <img loading='lazy' src={PrevIcon} alt='prev icon' />
          </button>
          <button
            className='carousel-control-next'
            type='button'
            data-bs-target='#carouselManagerProcurement'
            data-bs-slide='next'
          >
            <img loading='lazy' src={PrevIcon} alt='next icon' />
          </button>
        </div>
      </div>
      <div className='slider-card mb-64'>
        <div
          ref={carouselDocumentationRef}
          id='carouselDocumentation'
          className='carousel slide'
          data-bs-ride='carousel'
        >
          <div className='carousel-indicators'>
            <button
              className='active'
              type='button'
              data-bs-target='#carouselDocumentation'
              data-bs-slide-to='0'
              aria-current='true'
              aria-label='Slide 1'
            />
            <button
              type='button'
              data-bs-target='#carouselDocumentation'
              data-bs-slide-to='1'
              aria-label='Slide 2'
            />
            <button
              type='button'
              data-bs-target='#carouselDocumentation'
              data-bs-slide-to='2'
              aria-label='Slide 3'
            />
          </div>
          <div className='carousel-inner'>
            <div className='carousel-item active' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                className='d-block'
                src={DocumentationFirstIcon}
                alt='slider image'
              />
            </div>
            <div className='carousel-item' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                className='d-block'
                src={DocumentationSecondIcon}
                alt='slider image'
              />
            </div>
            <div className='carousel-item' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                className='d-block'
                src={DocumentationThirdIcon}
                alt='slider image'
              />
            </div>
          </div>
          <button
            className='carousel-control-prev'
            type='button'
            data-bs-target='#carouselDocumentation'
            data-bs-slide='prev'
          >
            <img loading='lazy' src={PrevIcon} alt='prev icon' />
          </button>
          <button
            className='carousel-control-next'
            type='button'
            data-bs-target='#carouselDocumentation'
            data-bs-slide='next'
          >
            <img loading='lazy' src={PrevIcon} alt='next icon' />
          </button>
        </div>
        <div className='content'>
          <h4 className='title-40 mb-24'>Документация</h4>
          <p className='text-20 mb-48'>
            Контролируйте доступ к рабочей документации и выявляйте проблемы на ранних
            стадиях, чтобы снизить затраты на исправление ошибок на этапе
            строительно-монтажных работ.
          </p>
          <div className='short-description mb-16'>
            <img loading='lazy' src={DocumentIcon} alt='document icon' />
            <p className='text-16'>100% контроль передачи и приёмки документации</p>
          </div>
          <div className='short-description mb-16'>
            <img loading='lazy' src={VisibleIcon} alt='visible icon' />
            <p className='text-16'>Отслеживание версий каждого комплекта документации</p>
          </div>
          <div className='short-description mb-48'>
            <img loading='lazy' src={EventIcon} alt='event icon' />
            <p className='text-16'>
              Автоматическое распределение рабочей документации по подрядчикам
            </p>
          </div>
          <button
            className='text-16 primary-button download'
            onClick={onButtonClick(TextTypes.documentation, FileNames.documentation)}
          >
            Скачать презентацию
          </button>
        </div>
      </div>
      <div className='slider-card mb-64'>
        <div className='content'>
          <h4 className='title-40 mb-24'>Контроль качества стройки</h4>
          <p className='text-20 mb-48'>
            Оперативно фиксируйте дефекты с помощью мобильного приложения и отслеживайте
            динамику их устранения на интерактивном плане здания.
          </p>
          <div className='short-description mb-16'>
            <img loading='lazy' src={TopIcon} alt='top icon' />
            <p className='text-16'>Рейтинг субподрядчиков</p>
          </div>
          <div className='short-description mb-16'>
            <img loading='lazy' src={MonitorIcon} alt='monitor icon' />
            <p className='text-16'>Подробные отчёты о состоянии дефектов</p>
          </div>
          <div className='short-description mb-48'>
            <img loading='lazy' src={ChartsIcon} alt='charts icon' />
            <p className='text-16'>
              Аналитика планового и фактического времени устранения дефектов
            </p>
          </div>
          <button
            className='text-16 primary-button download'
            onClick={onButtonClick(TextTypes.controlQuality, FileNames.controlQuality)}
          >
            Скачать презентацию
          </button>
        </div>
        <div
          ref={carouselControlQualityRef}
          id='carouselControlQuality'
          className='carousel slide'
          data-bs-ride='carousel'
        >
          <div className='carousel-indicators'>
            <button
              className='active'
              type='button'
              data-bs-target='#carouselControlQuality'
              data-bs-slide-to='0'
              aria-current='true'
              aria-label='Slide 1'
            />
            <button
              type='button'
              data-bs-target='#carouselControlQuality'
              data-bs-slide-to='1'
              aria-label='Slide 2'
            />
            <button
              type='button'
              data-bs-target='#carouselControlQuality'
              data-bs-slide-to='2'
              aria-label='Slide 3'
            />
          </div>
          <div className='carousel-inner'>
            <div className='carousel-item active' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                className='d-block'
                src={ControlQualityFirstIcon}
                alt='control quality first'
              />
            </div>
            <div className='carousel-item' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                className='d-block '
                src={ControlQualitySecondIcon}
                alt='control quality second'
              />
            </div>
            <div className='carousel-item' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                className='d-block'
                src={ControlQualityThirdIcon}
                alt='control quality third'
              />
            </div>
          </div>
          <button
            className='carousel-control-prev'
            type='button'
            data-bs-target='#carouselControlQuality'
            data-bs-slide='prev'
          >
            <img loading='lazy' src={PrevIcon} alt='prev icon' />
          </button>
          <button
            className='carousel-control-next'
            type='button'
            data-bs-target='#carouselControlQuality'
            data-bs-slide='next'
          >
            <img loading='lazy' src={PrevIcon} alt='prev icon' />
          </button>
        </div>
      </div>
      <div className='slider-card mb-64'>
        <div
          ref={carouselMonitoringRef}
          id='carouselMonitoring'
          className='carousel slide'
          data-bs-ride='carousel'
        >
          <div className='carousel-indicators'>
            <button
              className='active'
              type='button'
              data-bs-target='#carouselMonitoring'
              data-bs-slide-to='0'
              aria-current='true'
              aria-label='Slide 1'
            />
            <button
              type='button'
              data-bs-target='#carouselMonitoring'
              data-bs-slide-to='1'
              aria-label='Slide 2'
            />
          </div>
          <div className='carousel-inner'>
            <div className='carousel-item active' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                className='d-block'
                src={MonitoringFirstIcon}
                alt='monitoring first icon'
              />
            </div>
            <div className='carousel-item' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                className='d-block'
                src={MonitoringSecondIcon}
                alt='monitoring second icon'
              />
            </div>
          </div>
          <button
            className='carousel-control-prev'
            type='button'
            data-bs-target='#carouselMonitoring'
            data-bs-slide='prev'
          >
            <img loading='lazy' src={PrevIcon} alt='prev icon' />
          </button>
          <button
            className='carousel-control-next'
            type='button'
            data-bs-target='#carouselMonitoring'
            data-bs-slide='next'
          >
            <img loading='lazy' src={PrevIcon} alt='prev icon' />
          </button>
        </div>
        <div className='content'>
          <h4 className='title-40 mb-24'>ИИ-мониторинг</h4>
          <p className='text-20 mb-48'>
            Получите полный контроль над площадкой с помощью объективных данных о
            прогрессе, которые соберет для вас искусственный интеллект.
          </p>
          <p className='text-16 medium mb-2'>Наружная архитектура и конструктив</p>
          <p className='text-16 mb-24'>
            ИИ отслеживает прогресс по бетонированию, кладке, остеклению в реальном
            времени. Проверяет порядок строительных работ и фиксирует нарушения техники
            безопасности на объекте.
          </p>
          <p className='text-16 medium mb-2'>Внутренняя архитектура и сети</p>
          <p className='text-16 mb-16'>
            ИИ показывает прогресс по отделке, К1 и К2 сетям, вентиляции, сетям связи,
            противопожарным сетям и электрике. Распознает установку дверей, окон,
            оконечных устройств ЭМ и СУ.
          </p>
          <button
            className='text-16 primary-button download'
            onClick={onButtonClick(TextTypes.monitoring, FileNames.monitoring)}
          >
            Скачать презентацию
          </button>
        </div>
      </div>
      <div className='slider-card mb-64'>
        <div className='content'>
          <h4 className='title-40 mb-24'>Безопасность</h4>
          <p className='text-20 mb-48'>
            Обеспечьте повышенную безопасность на объекте за счёт внедрения индивидуальных
            электронных пропусков. А ещё — отслеживайте количество задействованных рабочих
            и фиксируйте время их реального нахождения на объекте.
          </p>
          <div className='short-description mb-16'>
            <img loading='lazy' src={PassIcon} alt='pass icon' />
            <p className='text-16'>Пропуск в мобильном приложении</p>
          </div>
          <div className='short-description mb-16'>
            <img loading='lazy' src={BlockedDocumentIcon} alt='blocked document icon' />
            <p className='text-16'>Управление уровнем доступа и чёрный список</p>
          </div>
          <div className='short-description mb-48'>
            <img loading='lazy' src={StepIcon} alt='step icon' />
            <p className='text-16'>Отслеживание перемещения рабочих между объектами</p>
          </div>
          <button
            className='text-16 primary-button download'
            onClick={onButtonClick(TextTypes.security, FileNames.security)}
          >
            Скачать презентацию
          </button>
        </div>
        <div
          ref={carouselSecurityRef}
          id='carouselSecurity'
          className='carousel slide'
          data-bs-ride='carousel'
        >
          <div className='carousel-indicators'>
            <button
              className='active'
              type='button'
              data-bs-target='#carouselSecurity'
              data-bs-slide-to='0'
              aria-current='true'
              aria-label='Slide 1'
            />
            <button
              type='button'
              data-bs-target='#carouselSecurity'
              data-bs-slide-to='1'
              aria-label='Slide 2'
            />
            <button
              type='button'
              data-bs-target='#carouselSecurity'
              data-bs-slide-to='2'
              aria-label='Slide 3'
            />
          </div>
          <div className='carousel-inner'>
            <div className='carousel-item active' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                src={SecurityFirstIcon}
                className='d-block'
                alt='security first icon'
              />
            </div>
            <div className='carousel-item' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                src={SecuritySecondIcon}
                className='d-block'
                alt='security second icon'
              />
            </div>
            <div className='carousel-item' data-bs-interval={carouselInterval}>
              <img
                loading='lazy'
                src={SecurityThirdIcon}
                className='d-block'
                alt='security third icon'
              />
            </div>
          </div>
          <button
            className='carousel-control-prev'
            type='button'
            data-bs-target='#carouselSecurity'
            data-bs-slide='prev'
          >
            <img loading='lazy' src={PrevIcon} alt='prev icon' />
          </button>
          <button
            className='carousel-control-next'
            type='button'
            data-bs-target='#carouselSecurity'
            data-bs-slide='next'
          >
            <img loading='lazy' src={PrevIcon} alt='prev icon' />
          </button>
        </div>
      </div>
    </section>
  );
};
