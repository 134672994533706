/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DocsFiles
 */
export interface DocsFiles {
  /**
   *
   * @type {number}
   * @memberof DocsFiles
   */
  readonly id: number;
  /**
   * Название
   * @type {string}
   * @memberof DocsFiles
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DocsFiles
   */
  readonly url: string;
}

/**
 * Check if a given object implements the DocsFiles interface.
 */
export function instanceOfDocsFiles(value: object): value is DocsFiles {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('url' in value) || value['url'] === undefined) return false;
  return true;
}

export function DocsFilesFromJSON(json: any): DocsFiles {
  return DocsFilesFromJSONTyped(json, false);
}

export function DocsFilesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DocsFiles {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    url: json['url'],
  };
}

export function DocsFilesToJSON(value?: Omit<DocsFiles, 'id' | 'url'> | null): any {
  if (value == null) {
    return value;
  }
  return {
    name: value['name'],
  };
}
