/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { Product, ProductDetail } from '../models/index';
import {
  ProductFromJSON,
  ProductToJSON,
  ProductDetailFromJSON,
  ProductDetailToJSON,
} from '../models/index';

export interface V1DocsFilesProductsRetrieveRequest {
  id: number;
}

/**
 *
 */
export class V1Api extends runtime.BaseAPI {
  /**
   */
  async v1DocsFilesProductsListRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<Product>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/docs_files/products/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(ProductFromJSON),
    );
  }

  /**
   */
  async v1DocsFilesProductsList(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<Product>> {
    const response = await this.v1DocsFilesProductsListRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async v1DocsFilesProductsRetrieveRaw(
    requestParameters: V1DocsFilesProductsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ProductDetail>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling v1DocsFilesProductsRetrieve().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/api/v1/docs_files/products/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue =>
      ProductDetailFromJSON(jsonValue),
    );
  }

  /**
   */
  async v1DocsFilesProductsRetrieve(
    requestParameters: V1DocsFilesProductsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ProductDetail> {
    const response = await this.v1DocsFilesProductsRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
